














































































































import router from "@/router";
import store from "@/store";
import { REST } from "@/_util/rest_call";
import { Component, Vue } from "vue-property-decorator";
import Net from "../../../common/net_interface";

@Component({
  name: "Register",
  data() {
    return {
      familyName: "",
      givenName: "",
      login: "",
      email: "",
      password: "",
      passwordAgain: "",
      show_pwd: false,
      agreeTermsAndCond: false,
    };
  },

  computed: {
    userName() {
      return this.$data.givenName + this.$data.familyName;
    },

    readyToSubmit() {
      return !(
        this.$data.givenName.trim().length !== 0 &&
        this.$data.familyName.trim().length !== 0 &&
        this.$data.login.trim().length !== 0 &&
        this.$data.email.trim().length !== 0 &&
        this.$data.password.trim().length !== 0 &&
        this.$data.passwordAgain.trim().length !== 0 &&
        this.$data.password == this.$data.passwordAgain &&
        this.$data.agreeTermsAndCond == true
      );
    },

    userNameRules() {
      return [
        (v: string) => !!v || this.$t("register.username_is_required"),
        // (v: string) =>
        //   (v && v.length <= 10) || "Username must be less than 10 characters",
      ];
    },

    emailRules() {
      return [
        (v: string) => !!v || this.$t("register.email_is_required"),
        (v: string) =>
          /.+@.+\..+/.test(v) || this.$t("register.valid_email_required"),
      ];
    },

    passwordRule() {
      return [(v: string) => !!v || this.$t("register.password_is_required")];
    },

    passwordAgainRule() {
      return [
        (v: string) => !!v || this.$t("register.passwordAgain_is_required"),
        (v: string) =>
          v == this.$data.password ||
          this.$t("register.passwords_should_match"),
      ];
    },
  },

  methods: {
    backToLogin() {
      REST.logDebug("registerUser");
      router.push({ path: "/login" });
    },

    registerUser() {
      let updateUserObject = new Net.UpdateUser();
      let newUser = new Net.CreateUser(updateUserObject);

      newUser.is_amigo = false;
      newUser.is_admin = false;
      newUser.is_disabled = false;
      newUser.is_kid      = true;
      newUser.given_name = this.$data.givenName;
      newUser.family_name = this.$data.familyName;
      newUser.login = this.$data.login;
      newUser.email = this.$data.email;
      newUser.password = this.$data.password;

      REST.logDebug("Register new user", this.$data.newUser);
      REST.logDebug("Pushing register user data to the server");

      REST.call(
        "POST",
        "/users/register",
        newUser,
        (r) => {
          if (r.ok == "register") {
            REST.logInfo(
              "Registration completed, lets direct the user to the login page"
            );
            router.push({ path: "/login" });
          }
          store.dispatch("setUser", r.detail);
          return r.detail;
        },
        async (r) => {
          REST.logError(" NewUser:", r);
        }
      );
    },
  },
})
export default class Register extends Vue {}
